<template>
  <div class="m1">
    <div class="m1-h">
      <h2>招聘信息列表</h2>
      <div class="m1-btns">
          <el-upload
            style="display:inline-block; margin: 0 10px"
            class="upload-demo"
            action="http://api.dreamzhankai.com/resource/uploads"
            :headers="{token: token}"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :before-remove="beforeRemove"
            name="files"
            multiple
            :on-exceed="handleExceed"
            :show-file-list="false">
            <el-button plain type="primary">导入</el-button>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  imports
} from '@/api/employment';
export default {
  name: 'index',
  data() {
    return {
      token: ''
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.token = localStorage.getItem('token')
  },
  methods: {
    toImports (id) {
      imports({
        resource_id: id
      }).then(res =>{
        if (res.data.msg == 'ok') {
          this.$message({
            type: 'success',
            message: '导入成功'
          });
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleSuccess (res) {
      console.log(res)
      this.toImports(res.id)
    }
  },
}
</script>
<style scoped lang="scss">
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.m1-r1{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.m1-text1{
  color: #409EFF;
}
.m1-btns{
  flex: 1;
  margin-left: 87px;
}
</style>
